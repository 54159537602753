// extracted by mini-css-extract-plugin
export var backButton = "blogPost-module--backButton--qFkgC";
export var blogPost = "blogPost-module--blogPost--AkQmK";
export var container = "blogPost-module--container--v1Jt7";
export var content = "blogPost-module--content--svR-x";
export var contentContainer = "blogPost-module--contentContainer--wxq9U";
export var contentWrapper = "blogPost-module--contentWrapper--JzLDE";
export var socialNetworks = "blogPost-module--socialNetworks--ro3J9";
export var tableWrapper = "blogPost-module--table-wrapper--xxPoD";
export var tag = "blogPost-module--tag--rFt9k";
export var tags = "blogPost-module--tags--JamUD";
export var text = "blogPost-module--text--tFBPc";
export var title = "blogPost-module--title--3M2fL";
export var titleWrapper = "blogPost-module--titleWrapper--Uhncz";