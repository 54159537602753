import React, { useEffect } from 'react'
import _ from 'lodash'
import { graphql, Link } from 'gatsby'
import * as styles from './blogPost.module.css'
import Layout from '../../components/Layout'
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
import isMobile from 'is-mobile'
import { generateTagLink, generateUrl, postHeaderImg } from '../../utils/util'
import PostHeader from '../../components/PostHeader'
import SocialNetworks from '../../components/SocialNetworks'

export default function BlogPost({ data }) {
    deckDeckGoHighlightElement()

    const { markdownRemark } = data
    const { frontmatter, html, excerpt } = markdownRemark

    const { slug, readingTime } = markdownRemark.fields

    const dir = _.trim(slug, '/');

    const {
        author,
        date,
        image,
        title,
        tags,
        description
    } = frontmatter;

    const postSlug = generateUrl(dir);
    const postUrl = `/blog/post/${postSlug}`;
    const headerImg = postHeaderImg(dir, postSlug);

    const jsonLDScript = 
        {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
                 "@type": "WebPage",
                 "@id": "https://kubevious.io/"
            },
            "headline": title,
            "image": [
                `https://kubevious.io${headerImg}`
            ],
            "url": `https://kubevious.io${postUrl}`,
            "name": title,
            "description": description || excerpt,
            "datePublished": new Date(date).toISOString(),
            "dateModified": new Date(date).toISOString(),
            "author": {
                "@type": "Person",
                "name": author?.name
            },
            "publisher": {
                "@type": "Organization",
                "name": "Kubevious",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://kubevious.io/img/logo.png"
                }
            }
        };
    

    useEffect(() => {
        if (isMobile()) {
            const elements = document.querySelectorAll('table')
            for (var element of elements) {
                const parent = element.parentNode
                const wrapper = document.createElement('div')
                const scrollBar = document.createElement('div')

                wrapper.style.overflowX = 'auto'

                scrollBar.innerHTML = tableScroll()

                parent.replaceChild(wrapper, element)
                wrapper.appendChild(scrollBar)
                wrapper.appendChild(element)
            }
        }
    }, [])

    const tableScroll = () => {
        const html = '<div class="scroll-wrapper">' +
                        '<div class="arrow">' +
                            '<div class="side-arrow left-top"></div>' +
                            '<div class="side-arrow left-bottom"></div>' +
                        '</div>' +
                        '<div class="scroll-text">scroll</div>' +
                        '<div class="arrow">' +
                            '<div class="side-arrow right-top"></div>' +
                            '<div class="side-arrow right-bottom"></div>' +
                        '</div>' +
                    '</div>'
        return html
    }

    return (
        <Layout
            sectionName="blog"
            seoUrl={postUrl}
            seoDescription={description || excerpt}
            seoTitle={`${markdownRemark.frontmatter.title} | Kubevious.io`}
            seoKeywords={tags}
            seoImage={headerImg}
            author={author?.name}
            seoType="article"
            jsonLDScript={jsonLDScript}
        >
            <div className={styles.contentWrapper}>
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <PostHeader
                            image={image}
                            title={title}
                            dir={dir}
                            authorInfo={author}
                            date={date}
                            postSlug={postSlug}
                            readingTime={readingTime}
                            tags={tags}
                            text={data.excerpt}
                        />

                        <div className={styles.container}>
                            <div className={styles.titleWrapper}>
                                <h2 className={styles.title}>
                                    {title}
                                </h2>
                            </div>

                            <div className={styles.blogPost}>
                                <div
                                    className={styles.text}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />

                                <div className={styles.tags}>
                                    {tags.map((tag) => (
                                        <Link
                                            to={`/blog/tag/${generateTagLink(
                                                tag
                                            )}`}
                                            key={tag}
                                            className={styles.tag}
                                        >
                                            #{tag}
                                        </Link>
                                    ))}
                                </div>

                                <div
                                    className={
                                        styles.socialNetworks
                                    }
                                >
                                    <SocialNetworks
                                        title={title}
                                        text={data.excerpt}
                                        tags={tags}
                                        url={postUrl}
                                    />
                                </div>

                                <div className={styles.backButton}>
                                    <Link to="/blog">Back to list</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            excerpt(pruneLength: 200)
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                tags
                description
                author {
                    name
                    avatar
                },
            }
            fields {
                slug
                readingTime {
                    minutes
                }
            }
        }
    }
`
